   
import * as React from "react"
import { Row, Col, Button } from "react-bootstrap"
import { graphql, Link, StaticQuery } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import  FaBack  from "../../../images/faback";

function Shuttle({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query ShuttleMe{
            allGraphCmsPageShuttle {    
                edges {
                  node {
                    pageTitle
                    headerImage {
                      id
                      imageAltText
                      galleryImage {
                        gatsbyImageData(quality: 60)
                        url
                      }
                    }
                    pageDescription {
                      html
                      markdown
                      raw
                      text
                    }
                  }
                }
              }
          }
        `}


    render={data => (   
            <div className="hotel-listings">
               <div class="back-main">
                                
                                <a id="primary-button" onClick={() => navigate(-1)}>
                               <FaBack />
                                 </a>
                               
                              
                        </div>
                <div className="header">
                {data.allGraphCmsPageShuttle.edges.map(({ node: about }) => (
                <div className="header-image">
                <div className="header-overlay"></div>
                  <img src={about.headerImage.galleryImage.url}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={about.headerImage.imageAltText}
                    />
                    <div className="header-text">
                        <h1>{about.pageTitle}</h1>
                    </div>
                
                </div> 
                ))}

                </div>
                <div className="info-main">
                {data.allGraphCmsPageShuttle.edges.map(({ node: about }) => (
                 <p>
                   
                    <div dangerouslySetInnerHTML={{ __html: about.pageDescription.html }} /> 
                 </p>
                ))}
                </div>
                <a href="#contact-us">
					<Button id="primary-button">Contact Us</Button>
				</a>

            </div>
      )}
    />
  );
}

export default Shuttle;



